<template>
    <div>
        <div style="width:800px;margin:30px auto 50px">
            <el-steps :active="active" align-center>
                <el-step title="基础信息"></el-step>
                <el-step title="结算信息"></el-step>
                <el-step title="照片信息"></el-step>
            </el-steps>
        </div>
        <!-- <div class="merchant-message">
            {{merchantMessage}}
        </div> -->
        <BaseInfo @change="getBaseInfo" :provinceCityArea="provinceList" key="1" :isUpdate="true" :baseData="baseInfo" v-show="active==0" :checkMsg="merchantMessage"></BaseInfo>
        <SettleInfo  @change="getSettleInfo" :merType="baseInfo.merType" :provinceCityArea="provinceList" key="2"  :isUpdate="true" :baseData="setterInfo" :checkMsg="merchantMessage" v-show="active==1"></SettleInfo>
        <ImgInfo  @change="getimgInfo" v-show="active==2" :accountType="accountType" key="3" :isUpdate="true" :baseData="imgInfo" :merType="baseInfo.merType"></ImgInfo>
    </div>
</template>
<script>
import BaseInfo from '@/components/merchantUpdateBase/baseInfo'
import SettleInfo from '@/components/merchantUpdateBase/settleInfo'
import ImgInfo from '@/components/merchantUpdateBase/imgInfo'
import { MerchantApi } from '@/api'
import { mapState} from 'vuex'
export default {
    components:{
        BaseInfo,
        SettleInfo,
        ImgInfo
    },
    data(){
        return{
            active: 0,
            baseInfo: {},
            setterInfo:{},
            imgInfo:{},
            params:{},
            accountType:'1',
            merchantNo:'',
            merchantMessage:'',
            merType: ''
        }
    },
    computed:{
        ...mapState('app', ['provinceCityArea']),
        provinceList(){
            return '' || this.provinceCityArea
        }
    },
    created(){
        this.merchantNo = this.$route.query.merchantNo
        MerchantApi.queryMerInfo(this.merchantNo,'checkUpdate')
            .then(res=>{
                if(res.success){
                    let baseInfo = this.filterObj(res.data.baseInfo,['merType','companyName','busiLicenseCode','legalName','busiLicensePeriod','merSubName','provinceCode','cityCode','areaCode','address','majorMcc','subMcc','contactName','mobileNo'])
                    let baseInfo2 = this.filterObj(res.data.businessInfo,['companyName','busiLicenseCode','legalName','busiLicensePeriod'])
                    let setterInfo = this.filterObj(res.data.baseInfo,['accountType','accountName','accountNo','accountMobileNo','bankName','bankCode','branchNo','accountProvince','accountCity','branchName','settleIdCard','settleIdCardPeriod','legalIdCard','legalCardPeriod'])
                    let setterInfo2 = this.filterObj(res.data.businessInfo,['accountType','accountName','accountNo','bankName','bankCode','branchNo','accountProvince','accountCity','branchName','settleIdCard','settleIdCardPeriod','legalIdCard','legalCardPeriod'])
                    let baseImg = this.filterObj(res.data.merFileItemInfo,['licenseImg'])
                    let settleImg = this.filterObj(res.data.merFileItemInfo,['accountImg','settleIdCardFaceImg','settleIdCardNationalEmblemImg','unLegalAuthorizeImg','accountPermitImg','legalIdCardFaceImg','legalIdCardNationalEmblemImg'])
                    this.imgInfo = this.filterObj(res.data.merFileItemInfo,['holdIdCardImg','doorImg','cashierImg','premisesImg','legalIdCardFaceImg','legalIdCardNationalEmblemImg','settleHoldIdCardImg'])
                    this.baseInfo = Object.assign(baseInfo,baseImg,baseInfo2)
                    this.setterInfo = Object.assign(setterInfo,settleImg,setterInfo2)
                    this.merchantMessage = res.data.checkMsg
                    this.$forceUpdate()
                }
            })
    },
    methods:{
        getBaseInfo(params,type){
            if(type=='next'){
                this.active ++
                this.baseInfo = params
            }
        },
        getSettleInfo(params,type){
            if(type=='next'){
                this.active ++
                this.setterInfo = params
                this.accountType = params.accountType
            } if(type=='prev'){
                this.active --
            }
        },
        async getimgInfo(params,type){
            if(type=='submit'){
                let baseInfo = this.filterObj(this.baseInfo,['address','areaCode','cityCode','contactName','merSubName','mobileNo','provinceCode','majorMcc','subMcc'])
                let bussineBase = this.filterObj(this.baseInfo,['busiLicenseCode','busiLicensePeriod','companyName','licenseImg','legalName'])
                let businessSetter = this.filterObj(this.setterInfo,['legalCardPeriod','legalIdCard'])
                let businessInfo = Object.assign(bussineBase,businessSetter)
                let settleBase = this.filterObj(this.setterInfo,['accountImg','accountCity','accountName','accountNo','accountPermitImg','legalIdCardFaceImg','legalIdCardNationalEmblemImg','accountProvince','accountType','bankName','branchName','bankCode','branchNo','settleIdCard','settleIdCardPeriod','settleIdCardFaceImg','settleIdCardNationalEmblemImg','unLegalAuthorizeImg'])
                let imgInfo = this.filterObj(params,[,'accountPermitImg','cashierImg','doorImg','holdIdCardImg','premisesImg','settleHoldIdCardImg','legalIdCardFaceImg','legalIdCardNationalEmblemImg'])
                let settleInfo = Object.assign(settleBase,imgInfo)
                this.params ={
                    baseInfo: baseInfo,
                    setterInfo: settleInfo,
                    businessInfo: businessInfo,
                    merType: this.baseInfo.merType
                }
                let result = await MerchantApi.checkEdit(this.merchantNo,this.params)
                if(result.success){
                    this.Message.success('修改成功');
                    this.$router.push({
                        name:'auditMerchantManage'
                    })
                }
            } if(type=='prev'){
                this.active --
            }
        },
    }
}
</script>